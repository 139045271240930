import { evaluate } from 'cel-js';

import { Injectable, computed, inject } from '@angular/core';
import { CerbosEffect, CerbosResource, CerbosRole } from '../cerbos/cerbos.policies';
import { ConfigService } from '../config/config.service';

/*
 * All types and interfaces were temporarily put here to keep all code in one file
 * Will be fixed before final PR
 */

// Any is legal in those casess

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type anything = any;

interface CerbosPrincipalPayload {
	id: string;
	role: CerbosRole;
	attr: anything;
}

interface CerbosResourcePayload {
	kind: CerbosResource;
	attr?: anything;
}

interface CerbosRequest {
	principal: CerbosPrincipalPayload;
	resource: CerbosResourcePayload;
}

export interface CerbosResourceConfig {
	resourcePolicy: CerbosResourcePolicy;
}

interface CerbosResourcePolicy {
	resource: string;
	functions: Record<string, string>;
	rules: CerbosRule[];
}

interface CerbosRule {
	action: string;
	role: CerbosRole;
	effect: string;
	condition: string;
}

@Injectable({
	providedIn: 'root'
})
export class CerbosService {
	configService = inject(ConfigService);

	private readonly policies = computed(() => {
		const policies = this.configService.policiesPermissonsWithLocalUpdates();

		return policies;
	});

	private substitudeFunctions(functions: Record<string, string>, condition: string): string {
		const regex = /\{\{(\w+)\}\}/g;

		return condition.replace(regex, (match, functionName) => {
			return functions[functionName] || match;
		});
	}

	private evaluateCelCondition(condition: string, functions: Record<string, string>, request: CerbosRequest): boolean {
		const parsedCondition = this.substitudeFunctions(functions, condition);
		const res = evaluate(parsedCondition, { request });

		return res as boolean;
	}

	private evaluateSinglePolicyPermissions(request: CerbosRequest, policy: CerbosResourcePolicy) {
		const { principal, resource } = request;

		// Function to check if an action is allowed for a given resource
		const checkPermission = (resource: CerbosResourcePayload, action: anything) => {
			if (resource.kind !== policy.resource) {
				return false;
			}

			for (const rule of policy.rules) {
				if (rule.action === action && principal.role === rule.role) {
					const allowed = rule.effect === CerbosEffect.Allow;

					if (rule.condition) {
						return allowed && this.evaluateCelCondition(rule.condition, policy.functions, request);
					} else {
						return allowed;
					}
				}
			}

			return false;
		};

		// Return an object with isAllowed function
		return {
			isAllowed: (action: string) => {
				return checkPermission(resource, action);
			}
		};
	}

	checkResource(request: CerbosRequest) {
		const policies = this.policies();

		return {
			isAllowed: (action: anything) => {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				return policies.some((p: any) => this.evaluateSinglePolicyPermissions(request, p).isAllowed(action));
			}
		};
	}
}
