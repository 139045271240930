import { Pipe, PipeTransform, inject } from '@angular/core';
import { AppSecurityUser } from '../app-security/app-security.service';
import { ConfigService } from '../config/config.service';
import { CerbosResource, CerbosValidationRequest, findResourceByAction } from './cerbos.policies';
import { CerbosService } from './cerbos.service';

@Pipe({ name: 'cerbosFilterList', standalone: true })
export class CerbosFilterListPipe implements PipeTransform {
	private cerbosService = inject(CerbosService);
	private configService = inject(ConfigService);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	transform(options: any[]): any {
		return options.filter(({ cerbosAction }) => (cerbosAction ? this.filterByPermission(cerbosAction) : true));
	}

	private filterByPermission(cerbosAction: string) {
		const user = this.configService.currentUser();
		const resource = findResourceByAction(cerbosAction);
		const request = this.createCerbosRequest(user as AppSecurityUser, resource as CerbosResource);
		const decision = this.cerbosService.checkResource(request as CerbosValidationRequest);

		return decision.isAllowed(cerbosAction);
	}

	private createCerbosRequest(user: AppSecurityUser, resource: CerbosResource): CerbosValidationRequest {
		return {
			principal: {
				id: user?.username,
				role: user?.role,
				attr: {
					ownership: user?.ownership
				}
			},
			resource: {
				kind: resource
			}
		};
	}
}
