/* eslint-disable @typescript-eslint/no-explicit-any */
import { DecimalPipe } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { ModelConsumeOutcomeValueMappers } from '@constants/part-attributes.constant';
import { PartNumberType } from '@enums/part-number-type.enum';
import { GetAttributesConfiguration } from '@graphql/part-attributes.queries';
import { AttributeConfiguration, AttributeConfigurations, AttributeType } from '@interfaces/part-attributes.interface';
import { AttributeValueOutput, QuotePart, QuotePartField, QuotePartWithAttributes } from '@interfaces/quote-part.interface';
import { AttributesConfigStoreService } from '@stores/attributes-config-store.service';
import { uniq } from 'lodash-es';
import { Observable, catchError, forkJoin, map, of, tap } from 'rxjs';
import { GqlServerService } from './gql-server.service';
import { RawPartsDataService } from './raw-parts-data-service';

interface GetAttributesConfigurationResponse {
	getAttributeConfigurations: AttributeConfigurations;
}

export function isPlainObject(value: unknown): value is object {
	return value?.constructor === Object;
}

export function isEmpty(value: unknown) {
	if (value === null || value === undefined) return true;

	if (Array.isArray(value) && value.length === 0) return true;

	if (isPlainObject(value) && Object.keys(value).length === 0) return true;

	if (typeof value === 'string' && value.length === 0) return true;

	return false;
}

const mapa = {
	[QuotePartField.attibute_check_passed]: 'attibute_check_passed',
	[QuotePartField.df_price]: 'df_price',
	[QuotePartField.df_status]: 'df_status',
	[QuotePartField.mi6_quote_id]: 'mi6_quote_id',
	[QuotePartField.mi6_quote_part_id]: 'mi6_quote_part_id',
	[QuotePartField.jpn]: 'CI_JPN',
	[QuotePartField.moq]: 'CI_Minimum_Order_Qty_MOQ',
	[QuotePartField.customer_pn]: 'CI_Customer_PN',
	[QuotePartField.region]: 'CI_Region',
	[QuotePartField.sca_confidence]: 'sca_confidence',
	[QuotePartField.sca_estimate_price]: 'sca_estimate_price',
	[QuotePartField.sca_price_is_actual]: 'sca_price_is_actual'
} as any;

@Injectable({
	providedIn: 'root'
})
export class AttributesConfigDataService {
	private readonly gql = inject(GqlServerService).main;
	private readonly store = inject(AttributesConfigStoreService);
	private readonly rawPartsApi = inject(RawPartsDataService);

	getPartNumbersAsLocalOptions(): Observable<any> {
		const jpns$ = of([]) ?? this.rawPartsApi.searchByPartNumber('', PartNumberType.JPN);
		const mpns$ = of([]) ?? this.rawPartsApi.searchByPartNumber('', PartNumberType.MPN);
		const mapToOption = (pn: string) => ({ attr_option_id: pn, name: pn });

		return forkJoin([jpns$, mpns$]).pipe(
			map(([jpns, mpns]) => ({
				jpn: jpns.map(mapToOption),
				customer_pn: mpns.map(mapToOption)
			}))
		);
	}

	getAttributesConfiguration(): Observable<AttributeConfigurations> {
		const attrsConfig$ = this.gql
			.query<GetAttributesConfigurationResponse>({
				query: GetAttributesConfiguration
			})
			.pipe(
				map((res: ApolloQueryResult<GetAttributesConfigurationResponse>) => res.data.getAttributeConfigurations),
				catchError(() => of({ options: [], attributes: [] }))
			);
		const pns$ = this.getPartNumbersAsLocalOptions();

		return forkJoin([attrsConfig$, pns$]).pipe(
			map(([attributesConfig, localOptions]) => {
				const updated = {
					attributes: attributesConfig.attributes.map(attr => {
						if (attr.field in localOptions) {
							return {
								...attr,
								type: 'listWithDynamicOptions'
							};
						}

						return attr;
					}),
					options: (attributesConfig.options as any[]).concat(Object.values(localOptions).flat())
				};

				return updated as AttributeConfigurations;
			}), // todo ikurc: move to app store
			tap(attributesConfig => this.store.setAttributesConfig(attributesConfig)) // todo ikurc: move to app store
		);
	}
}

@Injectable({
	providedIn: 'root'
})
export class AttributesConfigOperationsService {
	private readonly store = inject(AttributesConfigStoreService);
	private readonly decimalPipe = inject(DecimalPipe);

	getActualizedPartAttriubutes(rawPart: Partial<QuotePart>) {
		const attributes = this.createQutoPartAttributesFromRawPart(rawPart, this.store.state.attributesConfig());
		const requiredAttrs = this.getRequiredAttrsList({
			...rawPart,
			attributes
		} as QuotePartWithAttributes);

		return {
			attributes,
			requiredAttrs,
			checkPassed: requiredAttrs.length === 0
		};
	}

	getRawFieldsList() {
		return this.store.state.configMaps().rawFieldsList;
	}

	getAttrIdByField(field: string) {
		const map = this.store.state.configMaps().fieldToAttrIdMap;

		return Number(map[field]);
	}

	mapMissingAttributes(missingAttrsids: string[]) {
		const values = Object.values(this.store.state.configMaps().attrsGroupedBySection);

		return values
			.map(sectionAttrs => sectionAttrs.sort((a, b) => a.order - b.order))
			.flat()
			.filter(a => missingAttrsids.includes(a.attr_id.toString()))
			.map(c => c.name)
			.join(', ');
	}

	getAttrNameById(attr_id: string) {
		const map = this.store.state.configMaps().attrIdToNameMap;

		return map[attr_id] ?? 'Unknown attr';
	}

	mapPaginatedPartToRawPart(paginatedPart: any): QuotePart {
		const rawPart = {} as QuotePart;

		Object.keys(paginatedPart).forEach(key => (rawPart[mapa[key]] = paginatedPart[key]));

		return rawPart;
	}

	mapRawPartToPaginatedPart(rawPart: QuotePart): any {
		const paginagedPart = {} as any;

		Object.keys(mapa).forEach(key => (paginagedPart[key] = rawPart[mapa[key]]));

		return paginagedPart;
	}

	mapPartWithAttrsToRawPart(partWithAttributes: QuotePartWithAttributes, wholePartData = true): QuotePart {
		const { rawPartAtrributesMap, optionsMap } = this.store.state.configMaps();
		const dataAttributes = partWithAttributes?.attributes.reduce(
			(acc, { attr_id, value }) => ({ ...acc, [attr_id]: value }),
			{} as Record<number, string>
		) as any;

		const data = Object.entries(rawPartAtrributesMap).reduce((acc, [attr_id, { raw_part_field, type }]: any) => {
			const isList = type === AttributeType.list;
			const mapper = ModelConsumeOutcomeValueMappers[type as AttributeType] ?? (v => v);

			const value = dataAttributes![attr_id] ?? null;

			return { ...acc, [raw_part_field]: isList ? mapper(optionsMap[value] ?? null) : mapper(value) };
		}, {} as any);

		// TODO ikurc: temporary fix while we negotiating final SCA lambda interface + file upload pipeliens
		data['PI_No_of_Thread'] = dataAttributes[37] ? Number(dataAttributes[37]) : null;
		data['PI_No_of_Threads'] = dataAttributes[37] ? Number(dataAttributes[37]) : null;
		data['MTD_Surface_Roughness_m'] = dataAttributes[59] ? Number(dataAttributes[59]) : null;
		data['MTD_Additional_Machining_Axis'] = data['MTD_Additional_Machining_Axis'] ? Number(data['MTD_Additional_Machining_Axis']) : null;
		data['SOF_Base_Finish'] = null;
		data['SOF_Addtl_Finish'] = null;

		data.sca_estimate_price =
			partWithAttributes.sca_estimate_price !== null ? this.decimalPipe.transform(partWithAttributes.sca_estimate_price, '1.1-2') : null;

		return wholePartData ? { ...partWithAttributes, ...data } : data;
	}

	private getRequiredAttrsList(part: QuotePartWithAttributes) {
		const dataAttributes = part.attributes.reduce(
			(acc, { attr_id, value }) => ({ ...acc, [attr_id]: value }),
			{} as Record<number, string>
		);
		const attributes = [...Object.values(this.store.state.configMaps().attrsGroupedBySection).flat()] as any[];
		const mapped = attributes.map(a => ({
			id: a.attr_id,
			name: a.name,
			required: this.calculateDynamicOptions(a, dataAttributes, this.store.state.configMaps().optionsMap).required
		}));

		return mapped.filter(a => a.required && !dataAttributes[a.id]).map(c => c.name);
	}

	private createQutoPartAttributesFromRawPart(rawPart: any, config: any): AttributeValueOutput[] {
		const { attributes, options } = config;

		const optionsMap = {
			molded: 'Molded',
			sheet_metal: 'Sheet Metal',
			machined_metal: 'Machined',
			frame: 'Frame',
			cover: 'Cover',
			bracket: 'Bracket',
			plate: '',
			cylindrical: 'Cylindrical',
			gear: 'Gear',
			planar: 'Planar',
			rotational: 'Rotational',
			plastic: 'Plastics',
			metal: 'Metal',
			assembly: 'Mechanical Assembly',
			asia: 'Asia',
			america: 'Americas',
			europe: 'Europe'
		} as any;

		const mappedOptions: Record<string, number> = {};

		for (const option of options) {
			const name = option.name.toLowerCase();

			mappedOptions[name] = option.attr_option_id;
		}

		const values: AttributeValueOutput[] = [];

		for (const attribute of attributes) {
			if (!attribute.raw_part_field) continue;

			let value: unknown = rawPart[attribute.raw_part_field];

			if (isEmpty(value)) continue;

			if ((typeof value === 'string' || typeof value === 'number') && attribute.type === AttributeType.list) {
				const name = (optionsMap[value] || value).toString().trim().toLowerCase();

				if (name.length === 0) continue;

				const option = mappedOptions[name];

				if (!option) continue;

				value = option;
			}

			if (typeof value === 'string' && attribute.type === AttributeType.bool) {
				value = value.toLowerCase() === 'yes';
			}

			values.push({
				attr_id: attribute.attr_id,
				value: String(value)
			} as any);
		}

		return values;
	}

	private calculateDynamicOptions(
		attributeConfiguration: AttributeConfiguration,
		dataAttributes: Record<number, string>,
		optionsMap: any
	): any {
		// const dependsOn = uniqBy(attributeConfiguration.variations.map(v => v.conditions).flat(), 'attr_id').map(({ attr_id }) => attr_id);

		const variation = attributeConfiguration.variations.find(variant =>
			variant.conditions.every(condition => dataAttributes[condition.attr_id]?.toString() === condition.attr_option_id.toString())
		);

		const values = variation
			? variation.options
			: attributeConfiguration.options.length
				? attributeConfiguration.options
				: uniq(attributeConfiguration.variations.map(v => v.options).flat());

		const required = variation ? variation.required : attributeConfiguration.required;
		const options = values.map((attr_option_id: number) => ({ label: optionsMap[attr_option_id], value: String(attr_option_id) }));
		const disabled = !variation && attributeConfiguration.variations.length > 0;

		return {
			options,
			required,
			disabled
		};
	}
}
