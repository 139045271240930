import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { MiRoutes } from '@core/configs/routes';
import { AdminModuleActions } from '@core/services/cerbos/cerbos.policies';
import { AdminPersmissionsSerivce } from '@core/services/cerbos/resources/admin/admin-resource.service';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class AdminGuardService {
	private router = inject(Router);
	private adminPersmissionsSerivce = inject(AdminPersmissionsSerivce);

	public canActivate(): Observable<boolean | UrlTree> {
		const allowed = this.adminPersmissionsSerivce.checkPermission(AdminModuleActions.Access);

		return of(allowed).pipe(
			tap(allowed => {
				if (!allowed) {
					this.router.navigateByUrl(MiRoutes.noPermissions.url);
				}
			})
		);
	}
}

export const AdminModuleGuard: CanActivateFn = (): Observable<boolean | UrlTree> => inject(AdminGuardService).canActivate();
