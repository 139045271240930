import { Injectable, computed, inject } from '@angular/core';
import { QuotesStore } from '@stores/quotes.store';
import { CerbosPrincipalService } from '../../cerbos-principal.class';
import { CerbosResource } from '../../cerbos.policies';

@Injectable({
	providedIn: 'root'
})
export class QuotePersmissionsService extends CerbosPrincipalService {
	readonly quotesStore = inject(QuotesStore);

	readonly resource = computed(() => {
		const quote = this.quotesStore.currentQuote();

		return {
			kind: CerbosResource.Quote,
			attr: { quote }
		};
	});
}
