import { Component, inject } from '@angular/core';
import { RoleOption } from '@interfaces/roles.interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'mi-switch-role-modal',
	standalone: true,
	imports: [],
	templateUrl: './switch-role-modal.component.html',
	styleUrl: './switch-role-modal.component.scss'
})
export class SwitchRoleModalComponent {
	roleOption!: RoleOption;

	public activeModal = inject(NgbActiveModal);
}
