import { gql } from 'apollo-angular';

export const AppSecurityUsersQuery = gql`
	query GetQuoteModuleUserList {
		getQuoteModuleUserList {
			email
			firstname
			lastname
			roles
			username
			ownership {
				owns
				contributes
			}
		}
	}
`;

export const GetUserSelectedRole = gql`
	query GetUserSelectedRole {
		getUserSelectedRole
	}
`;

export const GetCerbosPolicies = gql`
	query GetCerbosPolicies {
		getCerbosPolicies {
			resourcePolicy {
				functions
				resource
				rules {
					action
					condition
					effect
					role
				}
			}
		}
	}
`;

export const UpdateCerbosPolicy = gql`
	mutation UpdateCerbosPolicy($policy: PolicyUpdateInput!) {
		updateCerbosPolicy(policy: $policy) {
			success
		}
	}
`;
