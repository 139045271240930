// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface CerbosValidationRequest<T = any> {
	principal: {
		id: string;
		role: CerbosRole;
		attr: T;
	};
	resource: {
		kind: CerbosResource;
		attr?: T;
	};
}

export enum CerbosRole {
	GCM = 'Global Commodity Manager',
	SCP = 'Supply Chain Professional',
	Engineer = 'Engineer',
	MCET = 'MCET',
	BusinessAdmin = 'Business Admin',
	SystemAdmin = 'System Admin',
	BU = 'BU'
}

export const RoleNamesMap = {
	[CerbosRole.GCM]: 'Commodity Manager',
	[CerbosRole.SCP]: 'Supply Chain Professional',
	[CerbosRole.Engineer]: 'Engineer',
	[CerbosRole.MCET]: 'MCET',
	[CerbosRole.BusinessAdmin]: 'Business Admin',
	[CerbosRole.SystemAdmin]: 'System Admin',
	[CerbosRole.BU]: 'BU'
} as Record<string, string>;

export enum CerbosEffect {
	Allow = 'EFFECT_ALLOW',
	Deny = 'EFFECT_DENY'
}

export enum CerbosResource {
	Quote = 'Quote',
	AdminPanel = 'AdminPanel',
	Drawing = 'Drawing'
}

export enum QuoteModuleActions {
	Access = `QUOTE_MODULE_ACCESS`,
	Create = `QUOTE_MODULE_CREATE_QUOTE`,
	Duplicate = `QUOTE_MODULE_DUPLICATE_QUOTE`,
	AddPart = `QUOTE_MODULE_ADD_PART_TO_QUOTE`,
	DeletePart = `QUOTE_MODULE_DELETE_PART_FROM_QUOTE`,
	InviteOwners = `QUOTE_MODULE_INVITE_OWNERS_TO_QUOTE`,
	InviteContributors = `QUOTE_MODULE_INVITE_CONTRIBUTORS_TO_QUOTE`,
	View = `QUOTE_MODULE_VIEW_QUOTE`,
	Close = `QUOTE_MODULE_CLOSE_QUOTE`,
	Edit = `QUOTE_MODULE_EDIT_QUOTE`,
	AssignRegion = `QUOTE_MODULE_ASSIGN_REGION_TO_PART`,
	AssignQuantity = `QUOTE_MODULE_ASSIGN_QUANTITY_TO_PART`,
	ConfigurePart = `QUOTE_MODULE_CONFIGURE_PART`,
	DeleteWhenNoSca = `QUOTE_MODULE_DELETE_WHEN_NO_SCA_RUN`,
	ViewSimilarParts = `QUOTE_MODULE_VIEW_SIMILAR_PARTS`,
	ViewSuggestedSuppliers = `QUOTE_MODULE_VIEW_SUGGESTED_SUPPLIERS`,
	SaveAndRunSca = `QUOTE_MODULE_SAVE_AND_RUN_SCA`,
	RequestAprioriEstimate = `QUOTE_MODULE_REQUEST_APRIORI_ESTIMATE`,
	VerifyAprioriEstimate = `QUOTE_MODULE_VERIFY_APRIORI_ESTIMATE`,
	UnverifyAprioriEstimate = `QUOTE_MODULE_UNVERIFY_APRIORI_ESTIMATE`,
	AddManualEstimate = `QUOTE_MODULE_ADD_MANUAL_ESTIMATE`,
	EditManualEstimate = `QUOTE_MODULE_EDIT_MANUAL_ESTIMATE`,
	VerifyManualEstimate = `QUOTE_MODULE_VERIFY_MANUAL_ESTIMATE`,
	UnverifyManualEstimate = `QUOTE_MODULE_UNVERIFY_MANUAL_ESTIMATE`,
	VerificationActions = `QUOTE_MODULE_VERIFICATION_ACTIONS_FOR_ESTIMATES`,

	// unused
	Search = `QUOTE_MODULE_SEARCH`
}

export enum AdminModuleActions {
	Access = `ADMIN_MODULE_ACCESS`,
	ImportRawPartsData = `ADMIN_MODULE_IMPORT_RAW_PARTS_DATA`,
	ImportThumbnails = `ADMIN_MODULE_IMPORT_THUMBNAILS`,
	DfConfiguration = `ADMIN_MODULE_DF_CONFIGURATION`
}

export enum DrawingsModuleActions {
	Access = `DRAWING_MODULE_ACCESS`
}

export const ActionToResourceMap = {
	[CerbosResource.AdminPanel]: [AdminModuleActions.Access],
	[CerbosResource.Quote]: [QuoteModuleActions.Access],
	[CerbosResource.Drawing]: [DrawingsModuleActions.Access]
} as Record<string, string[]>;

export function findResourceByAction(action: string): CerbosResource | undefined {
	const resource = Object.keys(ActionToResourceMap).find(resource => ActionToResourceMap[resource].includes(action));

	return resource as CerbosResource;
}

export const CerbosActionsLabelsMap = {
	// Quote module
	[QuoteModuleActions.Access]: 'Access Quote Module',
	[QuoteModuleActions.Create]: 'Create Quote',
	[QuoteModuleActions.Duplicate]: 'Duplicate Quote',
	[QuoteModuleActions.AddPart]: 'Add Part',
	[QuoteModuleActions.DeletePart]: 'Delete Part',
	[QuoteModuleActions.InviteOwners]: 'Invite Owners',
	[QuoteModuleActions.InviteContributors]: 'Invite Contributors',
	[QuoteModuleActions.View]: 'View Quote',
	[QuoteModuleActions.Close]: 'Close Quote',
	[QuoteModuleActions.Edit]: 'Edit Quote General Information',
	[QuoteModuleActions.AssignRegion]: 'Assign Region',
	[QuoteModuleActions.AssignQuantity]: 'Assign Quantity',
	[QuoteModuleActions.ConfigurePart]: 'Edit Part Attributes',
	[QuoteModuleActions.DeleteWhenNoSca]: 'Delete Quote without SCA',
	[QuoteModuleActions.ViewSimilarParts]: 'View Similar Parts',
	[QuoteModuleActions.ViewSuggestedSuppliers]: 'View Suggested Suppliers',
	[QuoteModuleActions.SaveAndRunSca]: 'Save & Run SCA',
	[QuoteModuleActions.RequestAprioriEstimate]: 'Request aPriori Estimate / Quote',
	[QuoteModuleActions.VerifyAprioriEstimate]: 'Verify aPriori Estimate',
	[QuoteModuleActions.UnverifyAprioriEstimate]: 'Undo Verification for aPriori Estimate',
	[QuoteModuleActions.AddManualEstimate]: 'Add Manual DF Estimate',
	[QuoteModuleActions.EditManualEstimate]: 'Edit Manual DF Estimate',
	[QuoteModuleActions.VerifyManualEstimate]: 'Verify Manual DF Estimate',
	[QuoteModuleActions.UnverifyManualEstimate]: 'Undo Verification for Manual DF Estimate',
	[QuoteModuleActions.VerificationActions]: 'Vefification actions',

	// Admin module
	[AdminModuleActions.Access]: 'Access Administration Module',
	[AdminModuleActions.ImportRawPartsData]: 'Import Raw Parts Data',
	[AdminModuleActions.ImportThumbnails]: 'Import Thumbnails',
	[AdminModuleActions.DfConfiguration]: 'DF Configuration',

	// Drawing module
	[DrawingsModuleActions.Access]: 'Access Drawing Module'
} as Record<string, string>;

export enum CerbosCondition {
	isOwner = 'isOwner',
	isContributor = 'isContributor',
	isPrimaryQuoteOwner = 'isPrimaryQuoteOwner',
	isDfEstimateAuthor = 'isDfEstimateAuthor'
}

export const SupportedConditionsMap = {
	// Quote module
	[QuoteModuleActions.Duplicate]: [CerbosCondition.isOwner, CerbosCondition.isContributor],
	[QuoteModuleActions.Close]: [CerbosCondition.isOwner, CerbosCondition.isContributor],
	[QuoteModuleActions.View]: [CerbosCondition.isOwner, CerbosCondition.isContributor],
	[QuoteModuleActions.AddPart]: [CerbosCondition.isOwner, CerbosCondition.isContributor],
	[QuoteModuleActions.DeletePart]: [CerbosCondition.isOwner, CerbosCondition.isContributor],
	[QuoteModuleActions.InviteOwners]: [CerbosCondition.isOwner, CerbosCondition.isContributor],
	[QuoteModuleActions.InviteContributors]: [CerbosCondition.isOwner, CerbosCondition.isContributor],
	[QuoteModuleActions.Edit]: [CerbosCondition.isOwner, CerbosCondition.isContributor],
	[QuoteModuleActions.AssignRegion]: [CerbosCondition.isOwner, CerbosCondition.isContributor],
	[QuoteModuleActions.AssignQuantity]: [CerbosCondition.isOwner, CerbosCondition.isContributor],
	[QuoteModuleActions.ConfigurePart]: [CerbosCondition.isOwner, CerbosCondition.isContributor],
	[QuoteModuleActions.DeleteWhenNoSca]: [CerbosCondition.isOwner, CerbosCondition.isContributor],
	[QuoteModuleActions.ViewSimilarParts]: [CerbosCondition.isOwner, CerbosCondition.isContributor],
	[QuoteModuleActions.ViewSuggestedSuppliers]: [CerbosCondition.isOwner, CerbosCondition.isContributor],
	[QuoteModuleActions.SaveAndRunSca]: [CerbosCondition.isOwner, CerbosCondition.isContributor],
	[QuoteModuleActions.RequestAprioriEstimate]: [CerbosCondition.isOwner, CerbosCondition.isContributor],
	[QuoteModuleActions.VerifyAprioriEstimate]: [CerbosCondition.isOwner, CerbosCondition.isContributor],
	[QuoteModuleActions.UnverifyAprioriEstimate]: [CerbosCondition.isOwner, CerbosCondition.isContributor],
	[QuoteModuleActions.AddManualEstimate]: [CerbosCondition.isOwner, CerbosCondition.isContributor],
	[QuoteModuleActions.EditManualEstimate]: [CerbosCondition.isDfEstimateAuthor, CerbosCondition.isPrimaryQuoteOwner],
	[QuoteModuleActions.VerifyManualEstimate]: [CerbosCondition.isDfEstimateAuthor, CerbosCondition.isPrimaryQuoteOwner],
	[QuoteModuleActions.UnverifyManualEstimate]: [CerbosCondition.isDfEstimateAuthor, CerbosCondition.isPrimaryQuoteOwner]
} as Record<string, CerbosCondition[]>;
