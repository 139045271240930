import { Injectable, inject } from '@angular/core';
import { FeedbackModalComponent } from '@components/modals/feedback-modal/feedback-modal.component';
import { SwitchRoleModalComponent } from '@components/modals/switch-role-modal/switch-role-modal.component';
import { RoleNamesMap } from '@core/services/cerbos/cerbos.policies';
import { RoleOption } from '@interfaces/roles.interface';
import { JblModalProcessingComponent } from '@jbl-pip/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
	providedIn: 'root'
})
export class QuotesModalService {
	private readonly modalService = inject(NgbModal);
	private readonly roleNamesMap = RoleNamesMap;

	openProcessingModal(): NgbModalRef {
		const modalRef = this.modalService.open(JblModalProcessingComponent, { backdrop: 'static' });
		modalRef.componentInstance.message = {
			header: 'Creating quote...',
			body: 'Please don’t refresh your browser or exit this page until the process is finished.'
		};

		return modalRef;
	}

	openFeedbackModal(): NgbModalRef {
		const modalRef = this.modalService.open(FeedbackModalComponent, { backdrop: 'static' });

		return modalRef;
	}

	openChangeRoleModal(roleOption: RoleOption): NgbModalRef {
		const modalRef = this.modalService.open(SwitchRoleModalComponent, { centered: true, windowClass: 'qw-modal' });
		modalRef.componentInstance.roleOption = roleOption;

		return modalRef;
	}
}
