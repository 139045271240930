import { Injectable, computed, signal } from '@angular/core';
import {
	AppSecurityUser,
	LocalOwnerShipUpdatePayload,
	LocalPermissionUpdatePayload,
	OwnershipKey
} from '../app-security/app-security.service';
import { CerbosRole } from '../cerbos/cerbos.policies';

@Injectable({
	providedIn: 'root'
})
export class ConfigService {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private readonly config = signal({} as any, {});

	private readonly ownershipUpdates = signal([] as LocalOwnerShipUpdatePayload[]);
	private readonly policiesUpdates = signal([] as LocalPermissionUpdatePayload[]);

	public policiesPermissonsWithLocalUpdates = computed(() => {
		const permissionPolicies = this.config().permissionPolicies;
		const policiesUpdates = this.policiesUpdates() ?? [];

		if (!policiesUpdates.length) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return permissionPolicies.map((p: any) => p.resourcePolicy);
		}

		const updatesByResource = policiesUpdates.reduce(
			(acc, update) => {
				if (!acc[update.resource]) {
					acc[update.resource] = [];
				}
				acc[update.resource].push(update);
				return acc;
			},
			{} as Record<string, LocalPermissionUpdatePayload[]>
		);

		const updated = permissionPolicies
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			.map((p: any) => p.resourcePolicy)
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			.map((p: any) => {
				const localUpdatesForResource = updatesByResource[p.resource] || [];

				return {
					...p,
					rules: p.rules.map((rule: { role: string; action: string }) => {
						const matchingUpdates = localUpdatesForResource.filter(update => update.role === rule.role && update.action === rule.action);

						// Apply the most recent update (last in array) if any exists
						return matchingUpdates.length > 0 ? matchingUpdates[matchingUpdates.length - 1].newRuleValue : rule;
					})
				};
			});

		return updated;
	});

	public appSecurityUsersWithLocalUpdates = computed(() => {
		const users = this.config().appSecurityUsers;
		const ownershipUpdates = this.ownershipUpdates() ?? [];

		if (!ownershipUpdates.length) {
			return users;
		}

		const updated = users.map((user: AppSecurityUser) => {
			const localUpdatesForUser = ownershipUpdates.filter(u => u.userIds.includes(user.username));
			const newOwnership = localUpdatesForUser.reduce((ownership, update) => {
				const { quoteId, ownershipKey } = update as LocalOwnerShipUpdatePayload;

				return {
					...ownership,
					...(ownershipKey === 'owns' && { owns: ownership.owns.concat(quoteId) }),
					...(ownershipKey === 'contributes' && { contributes: ownership.contributes.concat(quoteId) })
				};
			}, user.ownership);

			return {
				...user,
				ownership: newOwnership
			};
		});

		return updated;
	});

	public currentUser = computed(() => {
		const cognitoUser = this.config().cognitoUser;
		const matchedUser = this.appSecurityUsersWithLocalUpdates().find(
			(user: AppSecurityUser) => user!.username === cognitoUser?.username
		) as AppSecurityUser;

		const LOCAL_DEV_ONLY_ROLE = localStorage.getItem('mi6_role');

		if (LOCAL_DEV_ONLY_ROLE) {
			matchedUser.role = JSON.parse(LOCAL_DEV_ONLY_ROLE);
			matchedUser.roles = [JSON.parse(LOCAL_DEV_ONLY_ROLE)];
		}

		return matchedUser ?? null;
	});

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	update(value: any) {
		const newConfig = { ...this.config(), ...value };

		this.config.set(newConfig);
	}

	updateOwnershipLocally(quoteId: string, userIds: string[], ownershipKey: OwnershipKey) {
		const newValue = this.ownershipUpdates().concat({
			quoteId,
			userIds,
			ownershipKey
		});

		this.ownershipUpdates.set(newValue);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	updatePermissionLocally(resource: string, role: string, action: string, newRuleValue: any) {
		const policies = this.policiesUpdates();
		const withNew = policies.concat({
			resource,
			role,
			action,
			newRuleValue
		});

		this.policiesUpdates.set(withNew);
	}

	getAppSecurityUsersAsIdToFullNameMap() {
		return this.appSecurityUsersWithLocalUpdates().reduce(
			(acc: Record<string, string>, user: AppSecurityUser) => {
				acc[user.username] = `${user.firstname} ${user.lastname}`;

				return acc;
			},
			{} as Record<string, string>
		);
	}

	getMcetUsersIds() {
		return (this.appSecurityUsersWithLocalUpdates() ?? []).filter(
			(user: AppSecurityUser) => user.roles.includes(CerbosRole.MCET) && this.currentUser().username !== user.username
		);
	}

	getAvailableRoles(): CerbosRole[] {
		const currentUser = this.currentUser();

		return currentUser.roles ?? [];
	}
}
