import { Injectable, inject } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { AppSecurityUsersQuery, GetCerbosPolicies, GetUserSelectedRole, UpdateCerbosPolicy } from '@graphql/user.queries';
import { GqlServerService } from '@services/gql-server.service';
import { Observable, catchError, forkJoin, map, of } from 'rxjs';
import { CerbosResource, CerbosRole } from '../cerbos/cerbos.policies';

export interface GerUserRoleResponse {
	getUserSelectedRole: CerbosRole;
}

export interface GerUserInfoResponse {
	getQuoteModuleUserList: AppSecurityUser[];
}

export interface AppSecurityUser {
	email: string;
	fullName?: string;
	firstname: string;
	lastname: string;
	roles: CerbosRole[];
	role: CerbosRole; // selected role from preferences
	username: string;
	ownership: {
		owns: string[];
		contributes: string[];
	};
}

export type OwnershipKey = 'owns' | 'contributes';

export interface LocalOwnerShipUpdatePayload {
	quoteId: string;
	userIds: string[];
	ownershipKey: 'owns' | 'contributes';
}

export interface LocalPermissionUpdatePayload {
	resource: string;
	role: string;
	action: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	newRuleValue: any;
}

@Injectable({
	providedIn: 'root'
})
export class AppSecurityService {
	private gql = inject(GqlServerService).main;

	fetchAppSecurityUsers(): Observable<AppSecurityUser[]> {
		const activeRole$ = this.gql
			.query<GerUserRoleResponse>({
				query: GetUserSelectedRole
			})
			.pipe(
				map((res: ApolloQueryResult<GerUserRoleResponse>) => res.data.getUserSelectedRole),
				catchError(() => of(null))
			);

		const appSecurityUsers$ = this.gql
			.query<GerUserInfoResponse>({
				query: AppSecurityUsersQuery
			})
			.pipe(
				map((res: ApolloQueryResult<GerUserInfoResponse>) => res.data.getQuoteModuleUserList),
				catchError(() => of([]))
			);

		return forkJoin([appSecurityUsers$, activeRole$]).pipe(
			map(([appSecurityUsers, activeRole]) =>
				appSecurityUsers.map((user: AppSecurityUser) => ({
					...user,
					fullName: `${user.firstname} ${user.lastname}`,
					role: activeRole as CerbosRole
				}))
			)
		);
	}

	fetchPermissionPolicies() {
		return (
			this.gql
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				.query<any>({
					query: GetCerbosPolicies
				})
				.pipe(
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					map((res: ApolloQueryResult<any>) => res.data.getCerbosPolicies),
					catchError(() => of(null))
				)
		);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	updateRule(resource: CerbosResource, payload: any) {
		const { action, role, effect, condition } = payload;

		return this.gql
			.mutate({
				mutation: UpdateCerbosPolicy,
				variables: {
					policy: {
						resource,
						action,
						role,
						effect,
						condition
					}
				}
			})
			.pipe(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				map((res: any) => res.data.updateCerbosPolicy),
				catchError(() => of({ success: false }))
			);
	}
}
