import { Inject, Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { MiRoutes } from '@core/configs/routes';
import { AmplifyAuth } from '@core/interfaces/auth.interface';
import { environment } from '@environment';
import { AWS_AMPLIFY_AUTH, AuthState, BROWSER_STORAGE, ICognitoUser, IUser, JblAuthBaseService, WINDOW } from '@jbl-pip/core-state';
import { catchError, from, map, of, tap } from 'rxjs';
import { UserStateService } from '../user-state/user-state.service';

@Injectable({
	providedIn: 'root'
})
export class AuthService extends JblAuthBaseService {
	protected signInUrl = environment.signInUrl;
	private readonly userStateService = inject(UserStateService);
	private readonly router = inject(Router);

	constructor(
		@Inject(BROWSER_STORAGE) public override storage: Storage,
		@Inject(AWS_AMPLIFY_AUTH) protected override auth: AmplifyAuth,
		@Inject(WINDOW) protected override window: Window
	) {
		super(storage, auth, window);
	}

	protected override mapUser(user: ICognitoUser): IUser | null {
		if (!user?.signInUserSession?.idToken?.payload) {
			return null;
		}

		const idToken = user.signInUserSession.idToken;
		return {
			userId: idToken.payload.identities?.[0]?.userId || '',
			username: idToken.payload.profile,
			jwtToken: idToken.jwtToken,
			email: idToken.payload.email,
			firstName: idToken.payload.name,
			lastName: idToken.payload.family_name,
			preferredName: ''
		};
	}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	public init(): void {}

	getCognitoUser() {
		return from(this.auth.currentUserPoolUser()).pipe(
			catchError(() => of(null as unknown as ICognitoUser)),
			map((cognitoUser: ICognitoUser) => this.mapUser(cognitoUser)),
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			tap((user: any) => {
				if (!user) {
					console.log('No cognito user');
					this.userStateService.setAuthState(AuthState.SIGNED_OUT);

					void this.router.navigate([MiRoutes.login.url]);
				} else {
					this.userStateService.setUser(user);
					this.userStateService.setAuthState(AuthState.SIGNED_IN);
				}
			})
		);
	}
}
