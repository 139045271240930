import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, inject, signal } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { NavSubHeaderComponent } from '@components/nav-sub-header/nav-sub-header.component';
import { MiRoutes, RouteNavLinksConfig } from '@core/configs/routes';
import { UserOptions } from '@core/configs/user-options';
import { CerbosFilterListPipe } from '@core/services/cerbos/cerbos-filter-list.pipe';
import { environment } from '@environment';
import { JblHeaderModule, JblProfileModule, JblToastModule, JblToastService } from '@jbl-pip/core';
import { CommonCoreSelectors } from '@jbl-pip/core-state';
import { JblIconModule } from '@jbl/foundation';
import { Store } from '@ngrx/store';
import { QuotesStore } from '@stores/quotes.store';
import { filter, map, merge } from 'rxjs';

const common = [RouterOutlet, NgIf, NgForOf, AsyncPipe];
const jbl = [JblHeaderModule, JblProfileModule, JblIconModule, JblToastModule];

@Component({
	selector: 'mi-main-layout',
	templateUrl: './main-layout.component.html',
	styleUrls: ['./main-layout.component.scss'],
	standalone: true,
	imports: [...common, ...jbl, NavSubHeaderComponent, CerbosFilterListPipe],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainLayoutComponent implements OnInit {
	readonly logo = environment.logo.imagePath;
	readonly user$ = inject(Store).select(CommonCoreSelectors.selectUser);
	readonly toastService = inject(JblToastService);
	readonly links = signal(RouteNavLinksConfig);
	readonly userOptions = signal(UserOptions);

	private readonly store = inject(QuotesStore);
	private readonly router = inject(Router);
	private readonly route = inject(ActivatedRoute);

	@Input()
	public showShadow = false;

	ngOnInit(): void {
		this.watchForActiveLinkUpdate();
	}

	onOptionClick(optionId: string) {
		switch (optionId) {
			case 'feedback':
				this.store.openFeedbackModal();
				break;
			case 'help':
				this.router.navigate([MiRoutes.helpCenter.url]);
				break;
			case 'admin':
				this.router.navigate([MiRoutes.admin.url]);
				break;
		}
	}

	private watchForActiveLinkUpdate() {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const initialUrl$ = this.route.data.pipe(map(() => (this.route.snapshot as any)._routerState.url));
		const eventUrl$ = this.router.events.pipe(
			filter((e: Event): e is NavigationEnd => e instanceof NavigationEnd),
			map((e: NavigationEnd) => e.urlAfterRedirects)
		);

		merge(initialUrl$, eventUrl$).subscribe(currentUrl => {
			const updated = this.links().map(link => ({ ...link, active: (link.active = currentUrl.includes(link.url)) }));

			this.links.set(updated);
		});
	}
}
