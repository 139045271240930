import { gql } from 'apollo-angular';

export const DeleteUserPreference = gql`
	mutation DeleteUserPreference($name: String, $type: UserPreferencesType) {
		deleteUserPreference(name: $name, type: $type) {
			success
		}
	}
`;

export const SetUserPreference = gql`
	mutation SetUserPreference($name: String!, $preferences: JSON!, $type: UserPreferencesType!) {
		setUserPreference(name: $name, preferences: $preferences, type: $type) {
			id
			name
			preferences
			type
			user_id
		}
	}
`;

export const GetUserPreference = gql`
	query GetUserPreference($name: String!, $type: UserPreferencesType!) {
		getUserPreference(name: $name, type: $type) {
			id
			name
			preferences
			type
			user_id
		}
	}
`;

export const GetUserPreferences = gql`
	query GetUserPreferences($type: UserPreferencesType) {
		getUserPreferences(type: $type) {
			id
			name
			preferences
			type
			user_id
		}
	}
`;
