import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import { JblCommonCoreActions } from '@jbl-pip/core-state';
import { Store } from '@ngrx/store';
import { AttributesConfigDataService } from '@services/attributes-config.service';
import { Auth } from 'aws-amplify';
import { combineLatest, lastValueFrom, tap } from 'rxjs';
import { AppSecurityService } from '../app-security/app-security.service';
import { AuthService } from '../auth/auth.service';
import { ConfigService } from '../config/config.service';

@Injectable({
	providedIn: 'root'
})
export class InitService {
	private authService = inject(AuthService);
	private store = inject(Store);
	private appSecurityService = inject(AppSecurityService);
	private attrConfigDataService = inject(AttributesConfigDataService);
	private configService = inject(ConfigService);

	initializeApp() {
		// Configure aws amplify
		Auth.configure(environment.userAuth);

		// Set permissions to empty array for backwards compatibility with jbl shared services
		this.store.dispatch(JblCommonCoreActions.setPermissions({ permissions: [] }));

		// Fetch cognito user, app security users and attribute configuration
		const cognitoUser$ = this.authService.getCognitoUser();
		const appSecurityUsers$ = this.appSecurityService.fetchAppSecurityUsers();
		const permissionPolicies$ = this.appSecurityService.fetchPermissionPolicies();
		const attirubteConfig$ = this.attrConfigDataService.getAttributesConfiguration();

		const init$ = combineLatest([cognitoUser$, appSecurityUsers$, permissionPolicies$, attirubteConfig$]).pipe(
			tap(([cognitoUser, appSecurityUsers, permissionPolicies, attirubteConfig]) => {
				this.configService.update({
					cognitoUser,
					appSecurityUsers,
					permissionPolicies,
					attirubteConfig
				});
			})
		);

		return () => lastValueFrom(init$);
	}
}
