import { Injectable, inject } from '@angular/core';
import * as Queries from '@graphql/preferences.queries';
import { Observable, map } from 'rxjs';
import { GqlServerService } from './gql-server.service';

export enum UserPreferencesType {
	Table = 'Table',
	Roles = 'Roles'
}

interface UserPreferencesOutput {
	id: number;
	name: string;
	preferences: string; // json
	type: string;
	user_id: string;
}

@Injectable({ providedIn: 'root' })
export class PreferencesService {
	private readonly gql = inject(GqlServerService).main;

	deleteUserPreference(name: string, type: UserPreferencesType) {
		return (
			this.gql
				.mutate({
					mutation: Queries.DeleteUserPreference,
					variables: {
						name,
						type
					}
				})
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				.pipe(map((r: any) => r.data.deleteUserPreference))
		);
	}

	setUserPreference(name: string, preferences: string, type: UserPreferencesType) {
		return (
			this.gql
				.mutate({
					mutation: Queries.SetUserPreference,
					variables: {
						name,
						preferences,
						type
					}
				})
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				.pipe(map((r: any) => r.data.setUserPreference))
		);
	}

	getUserPreference(name: string, type: UserPreferencesType) {
		return (
			this.gql
				.query({
					query: Queries.GetUserPreference,
					variables: {
						name,
						type
					}
				})
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				.pipe(map((r: any) => (r.data ? r.data.getUserPreference : {})))
		);
	}

	getUserPreferences(type: UserPreferencesType): Observable<UserPreferencesOutput[]> {
		return (
			this.gql
				.query({
					query: Queries.GetUserPreferences,
					variables: {
						type
					}
				})
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				.pipe(map((r: any) => r.data.getUserPreferences))
		);
	}
}
