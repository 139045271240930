import { CelParser } from './parser.js';
import { CelType, getCelType, getPosition, getResult, getUnaryResult, size } from './helper.js';
import { CelEvaluationError } from './index.js';
const parserInstance = new CelParser();
const BaseCelVisitor = parserInstance.getBaseCstVisitorConstructor();
const defaultFunctions = {
  size: size
};
export class CelVisitor extends BaseCelVisitor {
  constructor(context, functions) {
    super();
    this.context = context || {};
    this.functions = {
      ...defaultFunctions,
      ...(functions || {})
    };
    this.validateVisitor();
  }
  context;
  functions;
  expr(ctx) {
    return this.visit(ctx.conditionalOr);
  }
  conditionalOr(ctx) {
    let left = this.visit(ctx.lhs);
    if (ctx.rhs) {
      ctx.rhs.forEach(rhsOperand => {
        const right = this.visit(rhsOperand);
        const operator = ctx.LogicalOrOperator[0];
        left = getResult(operator, left, right);
      });
    }
    return left;
  }
  conditionalAnd(ctx) {
    let left = this.visit(ctx.lhs);
    if (ctx.rhs) {
      ctx.rhs.forEach(rhsOperand => {
        const right = this.visit(rhsOperand);
        const operator = ctx.LogicalAndOperator[0];
        left = getResult(operator, left, right);
      });
    }
    return left;
  }
  relation(ctx) {
    const left = this.visit(ctx.lhs);
    if (ctx.rhs) {
      const right = this.visit(ctx.rhs);
      const operator = ctx.ComparisonOperator[0];
      // todo fix type assertion
      return getResult(operator, left, right);
    }
    return left;
  }
  addition(ctx) {
    let left = this.visit(ctx.lhs);
    if (ctx.rhs) {
      ctx.rhs.forEach((rhsOperand, idx) => {
        const right = this.visit(rhsOperand);
        const operator = ctx.AdditionOperator[idx];
        left = getResult(operator, left, right);
      });
    }
    return left;
  }
  multiplication(ctx) {
    let left = this.visit(ctx.lhs);
    if (ctx.rhs) {
      ctx.rhs.forEach((rhsOperand, idx) => {
        const right = this.visit(rhsOperand);
        const operator = ctx.MultiplicationOperator[idx];
        left = getResult(operator, left, right);
      });
    }
    return left;
  }
  unaryExpression(ctx) {
    if (ctx.UnaryOperator) {
      const operator = ctx.UnaryOperator;
      const operand = this.visit(ctx.atomicExpression);
      return getUnaryResult(operator, operand);
    }
    return this.visit(ctx.atomicExpression);
  }
  parenthesisExpression(ctx) {
    return this.visit(ctx.expr);
  }
  listExpression(ctx) {
    const result = [];
    if (!ctx.lhs) {
      return [];
    }
    const left = this.visit(ctx.lhs);
    result.push(left);
    if (ctx.rhs) {
      for (const rhsOperand of ctx.rhs) {
        const right = this.visit(rhsOperand);
        result.push(right);
      }
    }
    if (!ctx.Index) {
      return result;
    }
    const index = this.visit(ctx.Index);
    const indexType = getCelType(index);
    if (indexType != CelType.int && indexType != CelType.uint) {
      throw new CelEvaluationError(`invalid_argument: ${index}`);
    }
    if (index < 0 || index >= result.length) {
      throw new CelEvaluationError(`Index out of bounds: ${index}`);
    }
    return result[index];
  }
  mapExpression(ctx) {
    const mapExpression = {};
    if (!ctx.keyValues) {
      return {};
    }
    let valueType = '';
    for (const keyValuePair of ctx.keyValues) {
      const [key, value] = this.visit(keyValuePair);
      if (valueType === '') {
        valueType = getCelType(value);
      }
      if (getCelType(key) != CelType.string) {
        throw new CelEvaluationError(`invalid_argument: ${key}`);
      }
      if (valueType !== getCelType(value)) {
        throw new CelEvaluationError(`invalid_argument: ${value}`);
      }
      mapExpression[key] = value;
    }
    if (!ctx.identifierDotExpression && !ctx.identifierIndexExpression) {
      return mapExpression;
    }
    return this.getIndexSection(ctx, mapExpression);
  }
  getIndexSection(ctx, mapExpression) {
    const expressions = [...(ctx.identifierDotExpression || []), ...(ctx.identifierIndexExpression || [])].sort((a, b) => getPosition(a) > getPosition(b) ? 1 : -1);
    return expressions.reduce((acc, expression) => {
      if (expression.name === 'identifierDotExpression') {
        return this.getIdentifier(acc, expression.children.Identifier[0].image);
      }
      const index = this.visit(expression.children.expr[0]);
      return this.getIdentifier(acc, index);
    }, mapExpression);
  }
  mapKeyValues(children) {
    const key = this.visit(children.key);
    const value = this.visit(children.value);
    return [key, value];
  }
  macrosExpression(ctx) {
    const macrosIdentifier = ctx.Identifier[0];
    const fn = this.functions[macrosIdentifier.image];
    if (fn) {
      return fn(...[...(ctx.arg ? [this.visit(ctx.arg)] : []), ...(ctx.args ? ctx.args.map(arg => this.visit(arg)) : [])]);
    }
    throw new Error(`Macros ${macrosIdentifier.image} not recognized`);
  }
  // these two visitor methods will return a string.
  atomicExpression(ctx) {
    if (ctx.Null) {
      return null;
    }
    if (ctx.parenthesisExpression) {
      return this.visit(ctx.parenthesisExpression);
    }
    if (ctx.StringLiteral) {
      return ctx.StringLiteral[0].image.slice(1, -1);
    }
    if (ctx.BooleanLiteral) {
      return ctx.BooleanLiteral[0].image === 'true';
    }
    if (ctx.Float) {
      return parseFloat(ctx.Float[0].image);
    }
    if (ctx.Integer) {
      return parseInt(ctx.Integer[0].image);
    }
    if (ctx.ReservedIdentifiers) {
      throw new Error('Detected reserved identifier. This is not allowed');
    }
    if (ctx.identifierExpression) {
      return this.visit(ctx.identifierExpression);
    }
    if (ctx.listExpression) {
      return this.visit(ctx.listExpression);
    }
    if (ctx.mapExpression) {
      return this.visit(ctx.mapExpression);
    }
    if (ctx.macrosExpression) {
      return this.visit(ctx.macrosExpression);
    }
    throw new Error('Atomic expression not recognized');
  }
  identifierExpression(ctx) {
    const data = this.context;
    const result = this.getIdentifier(data, ctx.Identifier[0].image);
    if (!ctx.identifierDotExpression && !ctx.identifierIndexExpression) {
      return result;
    }
    return this.getIndexSection(ctx, result);
  }
  identifierDotExpression(ctx, param) {
    const identifierName = ctx.Identifier[0].image;
    return this.getIdentifier(param, identifierName);
  }
  indexExpression(ctx) {
    return this.visit(ctx.expr);
  }
  getIdentifier(searchContext, identifier) {
    if (typeof searchContext !== 'object' || searchContext === null) {
      throw new Error(`Cannot obtain "${identifier}" from non-object context: ${searchContext}`);
    }
    const value = searchContext[identifier];
    if (value === undefined) {
      const context = JSON.stringify(this?.context);
      if (context === '{}') {
        throw new Error(`Identifier "${identifier}" not found, no context passed`);
      }
      throw new Error(`Identifier "${identifier}" not found in context: ${context}`);
    }
    return value;
  }
}
