@let allowedUserOptions = userOptions() | cerbosFilterList;
@let allowedLinks = links() | cerbosFilterList;

<div class="main-layout">
	<div class="header-wrapper">
		<jbl-header [showShadow]="false">
			<div class="header-logo">
				<jbl-logo [logo]="logo"></jbl-logo>
			</div>
			<jbl-nav-menu [links]="allowedLinks" class="header-nav"></jbl-nav-menu>

			<div class="header-actions">
				<jbl-icon class="jbl-mg-10" [type]="'solid'" [fill]="'--cool-gray-10'" [name]="'bell'" [width]="'20'" [height]="'20px'"></jbl-icon>
			</div>

			<div class="header-avatar header-nav-action" *ngIf="user$ | async as user">
				<jbl-profile [user]="user" [userOptions]="allowedUserOptions" (optionClick)="onOptionClick($event)"> </jbl-profile>
			</div>
		</jbl-header>
		<mi-nav-sub-header></mi-nav-sub-header>
	</div>

	<div class="jbl-toast-container" style="font-size: 12px">
		<jbl-toast *ngFor="let toast of toastService.toasts$ | async" [toast]="toast"> </jbl-toast>
	</div>

	<div class="main-layout__content">
		<router-outlet></router-outlet>
	</div>
</div>
