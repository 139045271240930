import { Injectable, computed, inject } from '@angular/core';
import { QuotesStore } from '@stores/quotes.store';
import { CerbosPrincipalService } from '../../cerbos-principal.class';
import { CerbosResource } from '../../cerbos.policies';

@Injectable({
	providedIn: 'root'
})
export class DrawingPersmissionsSerivce extends CerbosPrincipalService {
	readonly quotesStore = inject(QuotesStore);

	readonly resource = computed(() => {
		return {
			kind: CerbosResource.Drawing
		};
	});
}
